<template>
    <div>

        <section id="about-section-id" class="about-me-section section">
           <div class="about-me-section-header-and-info">
              
                <div class="about-me-header">
                    <p class="section-title">about me</p>
                </div>

                <div class="about-me-flex-container">

                    <div class="about-me-container">
                        <div class="about-me-image">
                            <div class="image-container">
                                
                                <div class="image">
                                    
                                    <div class="img-b-line"></div>

                                    <!-- <img src="../assets/me.svg" alt="My picture" id="amimg"> -->
                                    <img src="../assets/me.webp" alt="My picture" id="amimg">

                                    
                                </div>
                            </div>
                        </div>
                        <div class="about-me-info">
                            <div class="about-me-info-paragraph">

                                
                                <p class="about-paragraph fade-in-ams">My keen interest in software development started while at university and since then I have been exposed to exciting and cutting-edge technologies. I strive to create visually stunning apps that offer accessible and engaging experiences.</p>
                                <p class="about-paragraph fade-in-ams">Throughout my journey, I've had the opportunity to work on several projects that have allowed me to showcase my abilities and creativity. From designing simple web pages to building feature-rich applications.</p>
                                
                                
                                <!-- <p class="about-paragraph fade-in-ams">My keen interest in front-end development</p>
                                <p class="about-paragraph fade-in-ams">started while at university and since then I</p>
                                <p class="about-paragraph fade-in-ams">have been exposed to exciting and cutting-edge </p>
                                <p class="about-paragraph fade-in-ams">technologies. I strive to create visually</p>
                                <p class="about-paragraph fade-in-ams">stunning web apps and websites that offer </p>
                                <p class="about-paragraph fade-in-ams">accessible and engaging experiences.</p>
                                
                                <br>

                                <p class="about-paragraph fade-in-ams">Throughout my journey, I've had the opportunity</p>
                                <p class="about-paragraph fade-in-ams">to work on several projects that have allowed me</p>
                                <p class="about-paragraph fade-in-ams">to showcase my abilities and creativity. From </p>
                                <p class="about-paragraph fade-in-ams">designing responsive web pages to building </p>
                                <p class="about-paragraph fade-in-ams">feature-rich applications</p>
                                <p class="about-paragraph fade-in-ams"><a target="_blank" href="https://my-muzik.netlify.app/">like this spotify connected app </a>,</p>
                                <p class="about-paragraph fade-in-ams">each project has challenged me to learn something new.</p> -->


                           
                                <!-- <p class="about-paragraph fade-in-ams"> My keen interest in web development started while at university and since then I have been exposed to exciting and sharp new technologies. I strive to develop eye-catching web apps and websites with accessible interfaces and great experiences.</p> -->
                                <p style="padding-top: 2rem;" class="fade-in-ams">Some of the tools and technologies I have been using:</p>
                                
                                <ul class="tech-ul fade-in-ams">
                                    <li class="fade-in-ams">Python</li>
                                    <li class="fade-in-ams">JavaScript/TypeScript</li>
                                    <li class="fade-in-ams">PHP/Laravel</li>
                                    <li class="fade-in-ams">Vue.js / Nuxt.js</li>
                                    <li class="fade-in-ams">SQL / MySQL</li>
                                    <li class="fade-in-ams">Django</li>
                                </ul>

         
                                <!-- <div class="resume-button-container fade-in-ams">
                                    <button id="resumeBtn"><div id="animatingResumeButton"></div><a target="_blank" class="download-resume" href="https://stanceray.com/stancillous.pdf">my resume</a></button>
                                </div> -->
                                
                         
                            </div>
    
                        </div>
        
                       
                    </div>
    
                </div>
            </div>


        </section>
    </div>

</template>

<script>
    export default {
        name:'aboutComp'
    }
</script>

<style lang="scss" scoped>

</style>
