<template>
    <section id="services-section" class="mi-oud">
      <div class="mi-oud-wrapper">


          <div class="wcu">
            <div class="wcu-wrp">
              <h1 class="section-title">you'll get:</h1>
              <div class="wc-wrapper">

                <div class="wc-p-div bespoke">
                  <div class="svgp">
                   
                    <h3 class="services-h"> Bespoke designs that reflect your brand </h3>
                  <svg class="serv-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.7 199.25"><g id="Objects"><polygon points="195.16 19.49 195.16 192.91 16.53 192.91 16.53 152.91 124.82 152.91 6.54 34.62 34.82 6.34 155.16 126.68 155.16 19.49 195.16 19.49"/></g></svg>
                  
                  </div>
                  <div class="serv-p">
                    <p class="services-p">I create customized website designs that are unique to your brand. This ensures that your website reflects your brand personality, values, and vision, helping you stand out in a crowded market.</p>
                    <p></p>
                  </div>
                 
                </div>
                <div class="wc-p-div convert">
                  <div class="svgp">
                   
                    <h3 class="services-h"> Websites that convert </h3>
                  <svg class="serv-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.7 199.25"><g id="Objects"><polygon points="195.16 19.49 195.16 192.91 16.53 192.91 16.53 152.91 124.82 152.91 6.54 34.62 34.82 6.34 155.16 126.68 155.16 19.49 195.16 19.49"/></g></svg>
                  
                  </div>
                  <div class="serv-p">
                    <p class="services-p">You'll get a website that is expertly designed to captivate your audience and deliver results. With a focus on conversion optimization, I'll help you turn website visitors into paying customers and boost your business growth</p>
                    <p></p>
                  </div>
                 
                </div>
                <div class="wc-p-div user-centered">
                  <div class="svgp">
                   
                    <h3 class="services-h"> User-centered design approach </h3>
                  <svg class="serv-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.7 199.25"><g id="Objects"><polygon points="195.16 19.49 195.16 192.91 16.53 192.91 16.53 152.91 124.82 152.91 6.54 34.62 34.82 6.34 155.16 126.68 155.16 19.49 195.16 19.49"/></g></svg>
                  
                  </div>
                  <div class="serv-p">
                    <p class="services-p">My user-centered design approach ensures that every aspect of your website is focused on delivering an exceptional user experience. From navigation to content, I design with your users in mind.</p>
                    <p></p>
                  </div>
                 
                </div>
                <div class="wc-p-div scalable">
                  <div class="svgp">
                   
                    <h3 class="services-h"> Scalable websites that grow with your business </h3>
                  <svg class="serv-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.7 199.25"><g id="Objects"><polygon points="195.16 19.49 195.16 192.91 16.53 192.91 16.53 152.91 124.82 152.91 6.54 34.62 34.82 6.34 155.16 126.68 155.16 19.49 195.16 19.49"/></g></svg>
                  
                  </div>
                  <div class="serv-p">
                    <p class="services-p">I develop scalable websites that are designed to adapt and evolve alongside your business, providing you with a platform that can handle your needs both now and in the future.</p>

                    <p></p>
                  </div>
                 
                </div>


                
              </div>
            </div>
          </div>
         
          <div class="lst-wrapper">
            <div class="lt-div">
              <div class="lt-wrp">
                <h2 class="lst-txt">Put your brand in the spotlight</h2>
              </div>
            </div>
          </div>

          <div class="wcus">
            <div class="wcus-wrp">
              <div><h1>MY AIM IS </h1></div>
              <div><h1>TO PROVIDE</h1></div>

              <div class="marquee">
                <div class="marquee-track">
                  <div class="m-item">
                    <h1 class="mtxt">EXCEPTIONAL</h1>
                    <h1 class="mtxt">MEMORABLE</h1>
                    <h1 class="mtxt">REMARKABLE</h1>
                    <h1 class="mtxt">EXCEPTIONAL</h1>
                  </div>
                </div>
              </div>
              <div><h1>CUSTOMER EXPERIENCES</h1></div>
              
            </div>
          </div>

          
          <div class="mi-cta-rw">
            <div class="mi-cta">
              <h2>Ready for a site as premium as your brand?</h2>
              <div class="mc-div">
                <a href="mailto:stancillousray@gmail.com" class="mi-a">Let's talk</a>
              </div>
            </div>
          </div>
      </div>

    </section>
</template>

<script setup>
      
    

</script>

<style lang="scss" scoped>

$websiteColor:#bb2649;

$small-screen-p:2rem;

body{
  width: 100vw;
  overflow: hidden;
  p{
    font-size:2rem;
  }
}


.mi-oud{
    height: auto;
    // background-color: white;
    color: black;
    // padding: 16rem 0;
    padding: 10rem 0;
    margin: 0;
    .mi-oud-wrapper{
      max-width: 1200px;
      margin: 0 auto;
      padding: 2rem;
      .tab{
        padding: 2rem .4rem;
        .tbd{
          .tbdh{
            font-size: 5rem;
            text-transform: capitalize;
            font-weight: 500;
            // margin: 4rem 0;
            opacity: .96;
            span{
              position: relative;
              
              // &::after{
              //             content: '';
              //             display: block;
              //             height: .1rem;
              //             position: absolute;
              //             left: 0;
              //             background-color: rgb(70, 68, 68);
              //             bottom: -5%;
              //             right: 0;
              //         }
            }
            }

        }
      }
      .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        // height: 170vh;
        width: 100%;
        padding: 1rem .4rem;
        .text{
          font-weight: 600;

          h2{
            background: linear-gradient(
          to right,
          rgb(0, 0, 0) 50%,
          rgb(163, 155, 155) 50%
          // rgb(255, 255, 255) 50%,
          // rgb(142, 136, 136) 50%

        );
        font-size: 4rem;
        font-weight: 500;
        background-size: 200% 100%;
        // margin: 1rem 0;
        background-position-x: 100%;
        color: transparent;
        // line-height: 1.7;
        background-clip: text;
        -webkit-background-clip: text;
          }
        }
      }

      
      .wcu{
        margin-top: 13rem;
        color: black;
        .wcu-wrp{

          .section-title{
            padding: 3rem 0;
          }
          .wc-wrapper{
            .wc-p-div{
              // border: 1px solid;
              padding: 4rem 1.2rem;
              cursor: pointer;
              transition: background-color .2s ease-in-out;
              &:nth-child(1)
              {
                // padding-bottom: 5rem;
                border-top: 1px solid rgb(56, 52, 52);
                border-bottom: 1px solid rgb(56, 52, 52);
              }
              &:nth-child(2)
              {
                border-bottom: 1px solid rgb(56, 52, 52);
              }
              &:nth-child(3)
              {
                padding: 5rem 1.2rem;
                border-bottom: 1px solid rgb(56, 52, 52);
              }
              &:nth-child(4)
              {
                padding: 5rem 1.2rem;
                border-bottom: 1px solid rgb(56, 52, 52);
              }

              .svgp{
                display: flex;
                align-items: center;
                justify-content: space-between;
                svg{
                  margin:0 1rem;
                  width: 4rem;
                  transition: all .2s ease-in-out;
                }
                h3{
                  font-size: 3.7rem;
                  font-weight: 500;
                  padding-bottom: 2rem;

                }
                
                .active-svg{
                  transform: rotate(45deg);
                }
              }
              .serv-p{
                display: grid;
                grid-template-columns: 1fr 1fr;
                overflow: hidden;
                max-height: 0;
                transition:max-height .8s ease-in-out;
    will-change: max-height;
                p{
                  font-size: 1.6rem;
                  line-height: 1.7;
                  font-weight: 500;
                }
              }
              
              .show-p{
                max-height: 3000px;
              }
            }

            .bespoke:hover{
              background-color: pink;
            }
            .convert:hover{
              background-color: #efcf27;
            }
            .user-centered:hover{
              background-color: #874bf9;
            }
            .scalable:hover{
              background-color: #a3ec88;
            }

          }
        }
      }
      .lst-wrapper{
        margin-top: 9rem;
        display: none;
        overflow-x: hidden;
        .lt-div{
          .lt-wrp{
            .lst-txt{
              font-size: 7rem;
              white-space: nowrap;
              max-width: 100vw;
              font-weight: 500;
            }
          }
        }
      }

      .wcus{
        margin: 10rem auto;
        .wcus-wrp{
          h1{
            position: relative;
            // font-family: Lato, sans-serif;
            font-size: 4.8rem;
            font-weight: 500;
            overflow: hidden;
          }
          .marquee{
            height: 6.2rem;
            overflow: hidden;
            .marquee-track{
              height: 100%;
              width: 100%;
              position: relative;
              .m-item{
                text-align: left;
                padding-left: 4.5rem;
                height: 100%;
                width: 100%;
                .mtxt{
                  // font-family: Melodrama, sans-serif;
                  font-weight: 600;
                  opacity: 1;
                }
              }
            }
          }
        }
      }


      .mi-cta-rw{
        margin: 4rem 0;
        .mi-cta{
          padding: 2rem 0;
          h2{
            font-size: 2.3rem;
            // font-size: 4rem;

            font-weight: 500;
            // font-family: Melodrama, sans-serif;
            padding: 1rem 0;
          }
          .mc-div{
            margin: 1rem 0;
            border: 1px dotted $websiteColor;
            background-color: $websiteColor;
            opacity: background-color .4;
            padding: 1rem 1.5rem;
            height: 4.3rem;
            width: 4.3rem;
            border-radius: 50%;
            display: grid;
            cursor: pointer;
            place-items: center;
            a{
              font-size: 1.7rem;
              // font-family: Melodrama, sans-serif;
              text-decoration: none;
              white-space: nowrap;
              font-weight: 500;
              // font-family: 'Lexend Deca', sans-serif;
              color: black;
            }
          }
        }
      }
    }

}

@media screen and (max-width: 650px) {
  .mi-oud {
    // padding: 7rem 0;

    .mi-oud-wrapper{
      .tab .tbd .tbdh{
        font-size: 3.2rem;
      }
      .container .text h2{
        font-size: $small-screen-p;
      }
      
      // .wcu .wcu-wrp .wc-wrapper .wc-p-div{
      //   grid-template-columns: 1fr;
      // }

      .wcu .wcu-wrp .wc-wrapper .wc-p-div{
        .svgp{
          svg{
            width: 2.5rem;
          }
          h3{
            font-size: 2.2rem;
          }

        }
        .serv-p{
          grid-template-columns: 70% 30%;
          p{
            font-size: 1.5rem;
          }
        }
      }
  
      .lst-wrapper .lt-div .lt-wrp .lst-txt{
        font-size: 5rem;
      }
  
      .wcus .wcus-wrp{
        h1{
          font-size: 2.4rem;
        }
        .marquee{
          height: 3.2rem;
        }
      }
  
      .mi-cta-rw  .mi-cta{
        h2{
          font-size: 2.2rem;
        }
      }
    }
  }
  
  
}
@media screen and (max-width: 450px) {

  .mi-oud .mi-oud-wrapper .wcu .wcu-wrp .wc-wrapper .wc-p-div{
        .svgp{
          svg{
            width: 2.2rem;
          }
          h3{
            font-size: 1.9rem;
          }

        }
        .serv-p{
          grid-template-columns: 80% 20%;
          p{
            font-size: 1.4rem;
          }
        }
      }
  
  
  
}




</style>