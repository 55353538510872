<template>
     <section id="features-section-id" class="features-section">

        <div class="scroll-stn">
            <h1 style="text-align: center;" class="section-title">Let's talk about your brand</h1>
        
            <div class="stn stn-one">
                <div class="text-div">
                    <p>The aim is to not just make your website visually appealing, but also to draw in your desired audience with a seamless and sophisticated user experience that motivates them to stay and take action, leading to increased conversions.</p>
                </div>
                
            </div>
            <div class="stn stn-two">
                <div class="text-div">
                    <p>Don't settle for mere visual appeal. Go for exceptional web experiences that leave a lasting impression on your business and the people it serves</p>
                </div>
            </div>
            <div class="stn stn-three">
                <div class="text-div">
                    <p>Through a synergistic approach, I work closely with my clients to help bring their ideas to life. The collaborative journey revolves around identifying and tackling challenges, exploring innovative solutions, and reaching outstanding achievements</p>
                </div>
            </div>
        
        </div>

    </section>

</template>

<script>

export default{
        name:'featuresComp'
        
    }
</script>

<style lang="scss" scoped>

</style>