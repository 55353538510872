<template>
    <div>

        <section id="top-page-section-id" class="top-page-section section">
            <div class="bg-video-wrp">
                <div class="bgv">
                    <video src="../assets/bgvid.mp4" autoplay loop muted></video>
                </div>
            </div>
            <div class="top-page-content">
                <div  class="top-page-content-container">
                    <div class="greeting">
                      
                        <h1 class="greeting-header fade-in-title">Hi, my name is</h1>
                    </div>
                    <div class="name">
                        <h1 class="name-header fade-in-title">stancillous raymond.</h1>
                    </div>
                    <div class="profession">
                        <h1 class="profession-name fade-in-title">I design and build refreshing web applications.</h1>
                  
                    </div>
                    <div class="info">
                        <p class="fade-in-title"><span>I love creating beautiful things that live on the internet. I seek to create </span> web applications that not only engage users, but also
                            solve user problems through effective solutions.</p>

                        <!-- <p class="info-paragraph fade-in-title">I love creating beautiful things that live on the internet. I seek to create web applications <br> that not only engage users, but also -->
                            <!-- solve user problems through effective solutions.</p> -->
                    </div>

                    <div class="blog-ex-cta">
                        <div class="bxc-btn">
                            <a target="_blank" class="fade-in-title" href="https://blog.stanceray.com">I write, sometimes</a>
                        </div>
                    </div>
                </div>
                
            </div>

          


        </section> 


    </div>
</template>

<script>
    export default {
        name:'topSectionComp'
    }
</script>

<style lang="scss" scoped>

</style>
